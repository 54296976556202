/**
 * Type: ページ
 * What: チケット購入方法ページ
 */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import Image from '../../util/Image';
import { Button } from '../../components/Btn';

const pageTitle = 'チケット購入方法';
const pageDescription =
  'BOSS E・ZO FUKUOKAのチケット購入方法の説明ページです。専用のチケットサイトにてご購入いただくことができます。';
const pageSlug = 'ticket';
// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="section">
      <div className="container">
        <div className="btn-wrap price-btn mb-6">
          <Button href="#" className="brand tab-choice mr-2">
            チケット
            <br className="sp" />
            購入方法
          </Button>
          <Button href="/ticket/price" className="brand ml-2">
            料金
          </Button>
        </div>

        <h3 className="title is-3">チケット購入方法</h3>
        <div className="inner-slim">
          <article>
            <p>
              BOSS E・ZO FUKUOKAのチケットについては、
              <a
                href="https://ticket.e-zofukuoka.com/#/"
                target="_blank"
                rel="noopener noreferrer"
                className="tx-link"
              >
                専用チケットサイト
              </a>
              または空きがある場合のみ当日券売機にてご購入いただけます。
            </p>
            <p className="list-mark">
              <span className="attention">※</span>
              <a
                href="http://www.hkt48.jp/"
                target="_blank"
                rel="noopener noreferrer"
                className="tx-link"
              >
                HKT48 劇場
              </a>
              および
              <a
                href="https://www.yoshimoto.co.jp/fukuokagekijyo/"
                target="_blank"
                rel="noopener noreferrer"
                className="tx-link"
              >
                よしもと福岡 大和証券劇場
              </a>
              のチケットは、それぞれの公式サイトにてご購入ください。
            </p>
            <p className="list-mark">
              <span className="attention">
                ※絶景3兄弟SMBC日興証券「つりZO」「のぼZO」は当日券売機のみでご購入いただけます。
              </span>
            </p>
          </article>
        </div>
        <div className="btn-wrap mt-5">
          <Button
            href="https://ticket.e-zofukuoka.com/#/"
            className="btn-buy"
            target="_blank"
          >
            チケット購入
          </Button>
        </div>
        <div className="btn-wrap mt-3">
          <Button href="/ticket/">各施設の空き状況を確認する</Button>
        </div>
        <div className="btn-wrap mt-3 sp-mb20">
          <Button href="/group">20名以上の団体予約はこちら</Button>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h3 className="title is-3">事前にチケットを購入する方法</h3>
        <div className="inner-slim">
          <article>
            <p>チケットのお支払い方法は、以下の方法で可能です。</p>
            <div className="img-wrap">
              <ul className="payment">
                <li>
                  <Image src="img-paypay-icon.png" />
                </li>
                <li>
                  「クレジットカード決済
                  <br className="sp" />
                  （VISA/Master/JCB/AMEX/DINERS）」
                </li>
                <li>「Alipay+」</li>
              </ul>
            </div>
          </article>
          <h4 className="ttl-step">
            <span className="en2 bg-10">Step.1</span>
            <br className="sp" />
            <span>ご希望のチケットを探す</span>
          </h4>
          <article>
            <p>
              BOSS E・ZO
              FUKUOKAの公式サイトでご購入希望の施設のチケット購入ボタンを選択してください。
              <br />
              チケットサイトにて、日時/チケットの種類/枚数を選択してください。
            </p>
            <div className="btn-wrap mt-5">
              <Button
                href="https://ticket.e-zofukuoka.com/#/"
                className="btn-buy"
                target="_blank"
              >
                チケット購入
              </Button>
            </div>
            <div className="btn-wrap mt-3">
              <Button href="/ticket/">各施設の空き状況を確認する</Button>
            </div>
          </article>
          <h4 className="ttl-step">
            <span className="en2 bg-10">Step.2</span>
            <br className="sp" />
            <span>お客様情報を入力する</span>
          </h4>
          <article>
            <p>
              チケットを選択して問題がなければ、購入する方の情報をご入力ください。
              <br />
              入力完了後、利用規約をご覧いただき同意の上チェックボタンを押し、「Eメールアドレスの確認を実施」ボタンをクリックしてください。
            </p>
            <p className="list-mark">
              <span className="attention">※</span>
              @ticket.e-zofukuoka.comよりメールが届きますので、予め受信設定などでメールが届くように設定をお願いいたします。
            </p>
            <p className="list-mark">
              <span className="attention">※</span>
              メール受信後、15分以内に購入が無い場合は購入権利が無効となりますのでご注意ください。15分を過ぎた場合は、再度Step1から実施いただく事になります。
            </p>
          </article>
          <h4 className="ttl-step">
            <span className="en2 bg-10">Step.3</span>
            <br className="sp" />
            <span>支払い情報を入力する</span>
          </h4>
          <article>
            <p>
              ご入力いただいたEメールアドレス宛に購入手続きのご案内が送付されます。
              <br />
              受信したメールに記載のURLより支払い情報をご入力ください。
            </p>
            <div className="tac mt-5">
              <Image src="img-cupon-how.jpg" />
            </div>
          </article>
          <h4 className="ttl-step">
            <span className="en2 bg-10">Step.4</span>
            <br className="sp" />
            <span>購入手続き完了</span>
          </h4>
          <article>
            <p>
              最後に購入内容に間違いがないかご確認の上、問題がなければ「購入を確定」ボタンをクリックしてください。
            </p>
          </article>
          <h4 className="ttl-step">
            <span className="en2 bg-10">Step.5</span>
            <br className="sp" />
            <span>eチケット受領</span>
          </h4>
          <article>
            <p>
              ご入力いただいたEメールアドレス宛に購入手続き完了のお知らせが届きます。
              <br />
              メール内に入場時に必要なeチケットのURLが記載されておりますので、当日スマートフォンで該当URLを表示させるか、もしくはURL内に記載のeチケットを事前に印刷しご持参ください。
            </p>
          </article>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h3 className="title is-3">
          BOSS E・ZO FUKUOKAで当日チケットを購入する場合
        </h3>
        <div className="inner-slim">
          <article>
            <p>
              当日券がある場合に限り、
              <a
                href="https://ticket.e-zofukuoka.com/#/"
                target="_blank"
                rel="noopener noreferrer"
                className="tx-link"
              >
                専用チケットサイト
              </a>
              、BOSS E・ZO FUKUOKA 3F
              チケットブースにてチケットをご購入いただくこともできます。
            </p>
            <p>
              チケットブースでのチケットのお支払い方法は、以下の方法で可能です。
            </p>
            <div className="img-wrap">
              <ul className="payment">
                <li>
                  <Image src="img-paypay-icon.png" />
                </li>
                <li>「現金」</li>
                <li>
                  「クレジットカード決済
                  <br className="sp" />
                  （VISA/Master/JCB/AMEX/DINERS）」
                </li>
                <li>「Alipay+」</li>
                <li>「WeChatPay」</li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h3 className="title is-3">ご購入にあたっての注意事項</h3>
        <div className="inner-slim">
          <article>
            <p>
              ・ご購入の前に「BOSS E・ZO
              FUKUOKA　チケットサービス利用規約」及び「公式サイト内各アトラクション注意事項」をご確認ください。
            </p>
            <p>
              ・お客様の購入が完了した時点で公式サイト記載の注意事項及び申込み時の注意事項に同意したものとみなします。
            </p>
            <p>・購入完了後の内容の変更、キャンセル、再発行はできません。</p>
            <p>・お客様都合での払い戻しはいたしません。</p>
            <p>
              ・屋上アトラクションの悪天候中止の場合に限り、払い戻しの対応をさせていただきます。
            </p>
          </article>
        </div>
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
